import './_Create.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../Components/Buttons'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import TextareaAutosize from 'react-textarea-autosize'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { selectUser } from '../../redux/systemSlice'
import WriterOptions from '../../Components/WriterOptions/WriterOptions'
import { useLocalStorage } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import SubTopics from './Subtopics'
import useWriteEssay from '../../hooks/useWriteEssay'
import ErrorModal from '../../Components/Modals/Error'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import TitleQuality from './TitleQuality'

function Setup({
	type,
	docID,
	setSubscriptionModalOpen,
}: {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
}) {
	const defaultWordCountFF = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)
	const removeChooseSubtopics = useFeatureFlagVariantKey(FEATURE_FLAGS.REMOVE_CHOOSE_SUBTOPICS)
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const blurPast250Words = useFeatureFlagVariantKey(FEATURE_FLAGS.BLUR_PAST_250_WORDS)
	const promptQualityBar = useFeatureFlagVariantKey(FEATURE_FLAGS.PROMPT_QUALITY_BAR)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const [topicValue, setTopicValue] = useState<string>(documentState?.title ?? '')
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const user = useSelector(selectUser)
	const formValid = topicValue !== undefined && topicValue.length !== 0
	const [localUserWriterSelections, setLocalUserWriterSelections] = useLocalStorage(docID, blankUserWriterSelections)
	const noFreeWordLimit = blurPast250Words === 'test'
	const defaultWordCount = defaultWordCountFF === '150' ? 150 : 250

	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const { generateEssay, getSubtopics } = useWriteEssay({
		docID: docID,
		type: type,
		topicValue,
		setTopicValue,
		setLocalUserWriterSelections: (selections) => {
			setLocalUserWriterSelections(selections)
			dispatch(setUserWriterSelections({ docID, userWriterSelections: selections }))
		},
		openSubscriptionModal: () => setSubscriptionModalOpen(true),
		setErrorModalOpen: setErrorModalOpen,
	})

	useEffect(() => {
		if (!documentState.setup && blurPast250Words) {
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: { ...documentState.userWriterSelections, wordCount: defaultWordCount },
				})
			)
		}
	}, [documentState.setup, blurPast250Words, defaultWordCount])

	useEffect(() => {
		setTopicValue(documentState?.title ?? '')
	}, [documentState?.title])

	if (!!documentState.setup || !type) {
		return null
	}

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<div className="headerContainer">
				{!documentState.isOnSubtopicsStep && (
					<TextareaAutosize
						placeholder="Type your topic..."
						id="topicHeader"
						autoFocus
						onFocus={() => {
							setTopicInputFocused(true)
						}}
						onBlur={() => {
							setTopicInputFocused(false)
						}}
						onChange={(e) => {
							setTopicValue(e.target.value)
						}}
						minRows={1}
						maxRows={topicInputFocused ? undefined : 3}
						maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
						value={topicValue}
					/>
				)}
				{!documentState.isOnSubtopicsStep && (
					<>
						{documentState.isGeneratingSubtopics ? (
							<div className="topicDivider contentGenerating"></div>
						) : promptQualityBar === 'test' ? (
							<TitleQuality topic={topicValue} />
						) : (
							<div className="border-b border-gray-900 border-dotted" />
						)}
					</>
				)}
				{!documentState.isOnSubtopicsStep && (
					<WriterOptions
						type={type!}
						premiumUser={!!user.planId}
						formValid={formValid}
						localUserWriterSelections={localUserWriterSelections}
						docID={docID}
						setSubscriptionModalOpen={setSubscriptionModalOpen}
					/>
				)}
				{!documentState.isOnSubtopicsStep && (
					<Button
						type="secondary"
						onClick={() => {
							if (
								documentState.userWriterSelections.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT &&
								(noFreeWordLimit || user.planId)
							) {
								getSubtopics()
							} else {
								generateEssay(noFreeWordLimit)
								posthog.capture('generate-essay')
							}
						}}
						className="generateEssayButton"
						emphasize
						disabled={!formValid}
					>
						<span>
							{type === DOC_TEMPLATES.Writer
								? documentState.userWriterSelections.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT &&
								  (noFreeWordLimit || user.planId) &&
								  !(removeChooseSubtopics === 'test')
									? 'Choose subtopics'
									: 'Generate Essay'
								: 'Create Outline'}
						</span>
					</Button>
				)}
				{documentState.isOnSubtopicsStep && (
					<SubTopics
						generateEssay={() => {
							posthog.capture('generate-essay')
							generateEssay(noFreeWordLimit)
						}}
					/>
				)}
			</div>
		</>
	)
}

export default Setup
