import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import TextareaAutosize from 'react-textarea-autosize'
import { selectDocumentState, setTitle } from '../../redux/docSlice'
import './_Create.scss'
import { DocumentState } from '../../redux/types'
import DocumentWrapper from '../../Components/DocumentWrapper'
import Setup from './Setup'
import ErrorModal from '../../Components/Modals/Error'
import { useAtom } from 'jotai'
import { accountModalAtom } from './Create'

type CreateProps = {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
}

function Essay(props: CreateProps) {
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const [loadingDots, setLoadingDots] = useState<string>('.')
	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const [, setAccountModalState] = useAtom(accountModalAtom)
	const documentRef = useRef<HTMLDivElement>(null)
	const headerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		let intervalId: any

		if (documentState && (documentState.isHumanizing || documentState.isGenerating)) {
			intervalId = setInterval(() => {
				// Update dots: cycle from '' -> '.' -> '..' -> '...'
				setLoadingDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
			}, 500) // Update every 500 milliseconds
		} else {
			setLoadingDots('') // Reset dots when loading is false
		}

		return () => clearInterval(intervalId) // Cleanup on unmount or loading changes
	}, [documentState?.isHumanizing, documentState?.isGenerating])

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<div id="create" className="veryPaddedContainer">
				{documentState && (
					<Setup
						type={props.type}
						docID={props.docID}
						setSubscriptionModalOpen={(open) =>
							setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: open }))
						}
					/>
				)}
				{documentState && documentState.setup && (
					<>
						<div className="headerContainer" ref={headerRef}>
							<TextareaAutosize
								placeholder="Untitled"
								id="topicHeader"
								onChange={(e) => {
									dispatch(setTitle({ docID: props.docID, title: e.target.value }))
								}}
								onFocus={() => {
									setTopicInputFocused(true)
								}}
								onBlur={() => {
									setTopicInputFocused(false)
								}}
								minRows={1}
								maxRows={topicInputFocused ? undefined : 3}
								maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
								value={documentState.title}
							/>
							{documentState.isGenerating ||
							documentState.isHumanizing ||
							documentState.isTypingEssay ||
							documentState.isTypingAddMore ? (
								<div className="topicDivider contentGenerating"></div>
							) : (
								<div className="topicDivider dividingBorder"></div>
							)}
						</div>
					</>
				)}
				<div ref={documentRef}>
					{documentState && documentState.isHumanizing && (
						<div className="humanizingText">
							<h6 className="header-sm">Humanizing{loadingDots}</h6>
							<p>This may take up to a minute.</p>
						</div>
					)}
					{documentState && documentState.isGenerating && (
						<div className="humanizingText">
							<h6 className="header-sm">
								Writing {props.type === DOC_TEMPLATES.Outline ? 'outline' : 'essay'}
								{loadingDots}
							</h6>
							<p>This may take up to a minute.</p>
						</div>
					)}
					<div className={documentState && documentState.isHumanizing ? 'humanizing' : ''}>
						<DocumentWrapper docID={props.docID} type={props.type} />
					</div>
				</div>

				{/* {documentState?.content && !documentState.isGenerating && documentState.isTypingEssay && (
				<Basic
					onClick={() => {
						if (reader) reader.cancel()
					}}
					type="tertiary"
					CTA={<span>Stop generating</span>}
					className="abortButton"
				/>
			)} */}
			</div>
		</>
	)
}

export default Essay
