import { useEffect, useMemo, useState } from 'react'
import './_WriterOptions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import {
	CONSTANTS,
	TYPE_OPTIONS,
	TONE_OPTIONS,
	READING_LEVEL_OPTIONS,
	HUMANIZER_OPTIONS,
	DOC_TEMPLATES,
} from '../../constants'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import Button from '../Buttons'
import { isEqual } from 'lodash'
import { DocumentState, UserWriterSelections } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import WritingStyleSelection from './WritingStyle'
import ReferencesSelection from './References'
import { FaLock, FaX } from 'react-icons/fa6'
import { Tooltip } from '@mui/material'
import { IoInformationCircleOutline } from 'react-icons/io5'

type Props = {
	type: string
	premiumUser: boolean
	formValid: boolean
	localUserWriterSelections: UserWriterSelections
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
}

const AdditionalOptions = ({ docID }: { docID: string }) => {
	const [openOptions, setOpenOptions] = useState(false)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	const posthog = usePostHog()

	return (
		<>
			<div className={`optionsContainer ${openOptions && 'show'}`}>
				<div className="toneTypeContainer">
					<div className="text-xl mb-3 font-semibold">Select a reading level </div>
					<div className="toneTypeList">
						{READING_LEVEL_OPTIONS.map((str, index) => {
							return (
								<Button
									className={`toneTypeButton ${str === documentState.userWriterSelections?.readingLevel && 'selected'}`}
									key={str + index}
									type="primary"
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: docID,
												userWriterSelections: { readingLevel: str },
											})
										)
									}}
								>
									<span>{str}</span>
								</Button>
							)
						})}
					</div>
				</div>
				<div className="toneTypeContainer">
					<div className="text-xl mb-3 font-semibold">Select an essay tone </div>
					<div className="toneTypeList">
						{TONE_OPTIONS.map((str, index) => {
							return (
								<Button
									className={`toneTypeButton ${str === documentState.userWriterSelections?.tone && 'selected'}`}
									key={str + index}
									type="primary"
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: docID,
												userWriterSelections: { tone: str },
											})
										)
									}}
								>
									<span>{str}</span>
								</Button>
							)
						})}
					</div>
				</div>
			</div>
			<div className="expandButton">
				<Button
					type="primary"
					className="clickableExpandButton"
					onClick={(e) => {
						setOpenOptions(!openOptions)
						posthog.capture(openOptions ? 'less-options-clicked' : 'more-options-clicked')
					}}
				>
					<span className="fancy">{openOptions ? 'Fewer options' : 'More options'}</span>
				</Button>
			</div>
		</>
	)
}

function WriterOptions(props: Props) {
	const freeWordLimit = useFreeWordLimit()
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const customWritingStylesFF = useFeatureFlagVariantKey(FEATURE_FLAGS.CUSTOM_WRITING_STYLES)
	const stealthGptFF = useFeatureFlagVariantKey(FEATURE_FLAGS.STEALTH_GPT)
	const removeEssayType = useFeatureFlagVariantKey(FEATURE_FLAGS.REMOVE_ESSAY_TYPE)
	const humanizerLockFF = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZER_LOCK)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const customWritingStyles = customWritingStylesFF === 'test'

	//Set redux if we have local storage for this document on component mount
	useEffect(() => {
		if (!isEqual(props.localUserWriterSelections, blankUserWriterSelections)) {
			dispatch(
				setUserWriterSelections({
					docID: props.docID,
					userWriterSelections: props.localUserWriterSelections,
				})
			)
		}
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageCount = useMemo(() => {
		const wordCount = documentState.userWriterSelections?.wordCount ?? freeWordLimit
		return (wordCount / 500).toFixed(1)
	}, [documentState.userWriterSelections?.wordCount])

	const checkbox = (
		<div
			className={`flex justify-between gap-2 mt-12 items-center cursor-pointer ${user.planId ? 'group' : 'opacity-50'}`}
			onClick={(e) => {
				if (!user.planId) {
					props.setSubscriptionModalOpen(true)
					return
				}
				dispatch(
					setUserWriterSelections({
						docID: props.docID,
						userWriterSelections: {
							humanizerIntensity: documentState.userWriterSelections?.humanizerIntensity === 'NONE' ? 'HIGH' : 'NONE',
						},
					})
				)
			}}
		>
			<div className="flex gap-3 items-center grow">
				<Tooltip title={user.planId ? '' : 'Upgrade to our premium plan to enable this feature.'} placement="top-start">
					<div className="text-2xl font-semibold">Humanize Text</div>
				</Tooltip>
				{!user.planId && (
					<Tooltip
						title="Transform AI-generated text to undetectable, human writing. Our humanizer is optimized to pass Turnitin's detection."
						placement="top-start"
					>
						<div>
							<IoInformationCircleOutline className="text-2xl" />
						</div>
					</Tooltip>
				)}
			</div>
			<div className="flex justify-center items-center p-2 border border-primary group-hover:bg-secondary">
				{user.planId ? (
					<FaX
						className={documentState.userWriterSelections?.humanizerIntensity === 'NONE' ? 'invisible' : 'visible'}
					/>
				) : (
					<Tooltip title="Upgrade to our premium plan to enable this feature." placement="top-end">
						<div>
							<FaLock />
						</div>
					</Tooltip>
				)}
			</div>
		</div>
	)

	return (
		<>
			<div id="writerOptions">
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<h4 className="sliderValue header-lg">
							<input
								onChange={(e) => {
									dispatch(
										setUserWriterSelections({
											docID: props.docID,
											userWriterSelections: { wordCount: parseInt(e.target.value) },
										})
									)
								}}
								onBlur={() => {
									const input = documentState.userWriterSelections?.wordCount ?? freeWordLimit
									if (input > CONSTANTS.WORD_COUNT_MAX) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MAX },
											})
										)
									} else if (input < CONSTANTS.WORD_COUNT_MIN) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MIN },
											})
										)
									}
								}}
								type="number"
								className="value"
								value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
								placeholder=""
							/>
							<span className="units fancy">
								words<span className="pageCount">, or ~{pageCount} pages</span>
							</span>
						</h4>
						<input
							type="range"
							min={CONSTANTS.WORD_COUNT_MIN}
							max={CONSTANTS.WORD_COUNT_MAX}
							className="slider"
							value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
							onChange={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: props.docID,
										userWriterSelections: { wordCount: parseInt(e.target.value) },
									})
								)
							}}
						/>
						{user.planId && stealthGptFF === 'control' && (
							<div className="humanizerContainer">
								<div className="text-xl mb-3 font-semibold">Select humanizer intensity: </div>
								<div className="toneTypeList">
									{HUMANIZER_OPTIONS.map((str, index) => {
										return (
											<Button
												className={`humanizerOption ${
													str === documentState.userWriterSelections?.humanizerIntensity && 'selected'
												}`}
												key={str + index}
												type="secondary"
												onClick={(e) => {
													dispatch(
														setUserWriterSelections({
															docID: props.docID,
															userWriterSelections: { humanizerIntensity: str },
														})
													)
												}}
											>
												<span>{str}</span>
											</Button>
										)
									})}
								</div>
							</div>
						)}
						{stealthGptFF !== 'control' && user.planId && checkbox}
					</>
				)}
				{removeEssayType !== 'test' && (
					<div className="toneTypeContainer">
						<div className="text-xl mb-3 font-semibold">Select an essay type </div>
						<div className="toneTypeList">
							{TYPE_OPTIONS.map((str, index) => {
								return (
									<Button
										className={`toneTypeButton ${str === documentState.userWriterSelections?.type && 'selected'}`}
										key={str + index}
										type="primary"
										onClick={(e) => {
											dispatch(
												setUserWriterSelections({
													docID: props.docID,
													userWriterSelections: { type: str },
												})
											)
										}}
									>
										<span>{str}</span>
									</Button>
								)
							})}
						</div>
					</div>
				)}
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						{customWritingStyles && <WritingStyleSelection docID={props.docID} />}
						<ReferencesSelection docID={props.docID} />
						{!customWritingStyles && <AdditionalOptions docID={props.docID} />}
					</>
				)}
				{humanizerLockFF === 'test' && !user.planId && props.type !== DOC_TEMPLATES.Outline && checkbox}
				{props.formValid && !customWritingStyles && (
					<div className={`topicDivider dividingBorder ${props.type === DOC_TEMPLATES.Outline && 'topMargin'}`}></div>
				)}
			</div>
		</>
	)
}

export default WriterOptions
