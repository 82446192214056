import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Documents, ReferencesInfo, UserWriterSelections } from './types'
import { initialDocumentsState, newDocument } from './constants'
import { HUMANIZER_OPTIONS } from '../constants'

export const docSlice: any = createSlice({
	name: 'doc',
	initialState: initialDocumentsState as Documents,
	reducers: {
		createNewDocument: (state: Documents, action: PayloadAction<{ docID: string }>) => {
			state[action.payload.docID] = newDocument
		},
		setSetup: (state: Documents, action: PayloadAction<{ docID: string; setup: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				setup: action.payload.setup,
			}
		},
		setSetupChanged: (state: Documents, action: PayloadAction<{ docID: string; setupChanged: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				setupChanged: action.payload.setupChanged,
			}
		},
		setContent: (state: Documents, action: PayloadAction<{ docID: string; content: string }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				content: action.payload.content,
			}
		},
		streamNewContent: (state: Documents, action: PayloadAction<{ docID: string; content: string }>) => {
			if (action.payload.content !== null) {
				state[action.payload.docID] = {
					...state[action.payload.docID],
					content: (state[action.payload.docID].content ?? '') + action.payload.content,
				}
			} else {
				state[action.payload.docID] = {
					...state[action.payload.docID],
					content: state[action.payload.docID].content ?? '',
				}
				if (state[action.payload.docID].userWriterSelections?.humanizerIntensity !== HUMANIZER_OPTIONS[0]) {
					state[action.payload.docID] = {
						...state[action.payload.docID],
						needsHumanizing: true,
					}
				}
			}
		},
		streamAddMoreContent: (state: Documents, action: PayloadAction<{ docID: string; content: string }>) => {
			if (action.payload.content !== null) {
				state[action.payload.docID] = {
					...state[action.payload.docID],
					content: (state[action.payload.docID].content ?? '') + action.payload.content,
				}
			}
		},
		setJSONContent: (state: Documents, action: PayloadAction<{ docID: string; jsonContent: string }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				jsonContent: action.payload.jsonContent,
			}
		},
		setLastSavedContent: (state: Documents, action: PayloadAction<{ docID: string; lastSavedContent: string }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				lastSavedContent: action.payload.lastSavedContent,
			}
		},
		setTitle: (state: Documents, action: PayloadAction<{ docID: string; title: string }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				title: action.payload.title,
			}
		},
		setLastSavedTitle: (state: Documents, action: PayloadAction<{ docID: string; lastSavedTitle: string }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				lastSavedTitle: action.payload.lastSavedTitle,
			}
		},
		setUserWriterSelections: (
			state: Documents,
			action: PayloadAction<{ docID: string; userWriterSelections: UserWriterSelections }>
		) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				userWriterSelections: {
					...state[action.payload.docID].userWriterSelections,
					...action.payload.userWriterSelections,
				},
			}
		},
		setIsGenerating: (state: Documents, action: PayloadAction<{ docID: string; isGenerating: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				isGenerating: action.payload.isGenerating,
			}
		},
		setIsGeneratingSubtopics: (
			state: Documents,
			action: PayloadAction<{ docID: string; isGeneratingSubtopics: boolean }>
		) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				isGeneratingSubtopics: action.payload.isGeneratingSubtopics,
			}
		},
		setIsHumanizing: (state: Documents, action: PayloadAction<{ docID: string; isHumanizing: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				isHumanizing: action.payload.isHumanizing,
			}
		},
		setNeedsHumanizing: (state: Documents, action: PayloadAction<{ docID: string; needsHumanizing: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				needsHumanizing: action.payload.needsHumanizing,
			}
		},
		setIsTypingAddMore: (state: Documents, action: PayloadAction<{ docID: string; isTypingAddMore: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				isTypingAddMore: action.payload.isTypingAddMore,
			}
		},
		setIsTypingEssay: (state: Documents, action: PayloadAction<{ docID: string; isTypingEssay: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				isTypingEssay: action.payload.isTypingEssay,
			}
		},
		setIsOnSubtopicsStep: (state: Documents, action: PayloadAction<{ docID: string; isOnSubtopicsStep: boolean }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				isOnSubtopicsStep: action.payload.isOnSubtopicsStep,
			}
		},
		setReferences: (state: Documents, action: PayloadAction<{ docID: string; references: ReferencesInfo[] }>) => {
			state[action.payload.docID] = {
				...state[action.payload.docID],
				references: action.payload.references,
			}
		},
	},
})

export const {
	createNewDocument,
	setSetup,
	setSetupChanged,
	setContent,
	streamNewContent,
	streamAddMoreContent,
	setJSONContent,
	setLastSavedContent,
	setTitle,
	setLastSavedTitle,
	setUserWriterSelections,
	setIsGenerating,
	setIsGeneratingSubtopics,
	setIsHumanizing,
	setNeedsHumanizing,
	setIsTypingAddMore,
	setIsTypingEssay,
	setIsOnSubtopicsStep,
	setReferences,
} = docSlice.actions
export const selectDocumentState = (state: any, docID: string) => state.doc[docID]

export default docSlice.reducer
