import { useFeatureFlagVariantKey } from 'posthog-js/react'

export const FEATURE_FLAGS = {
	PRIMARY_COLOR_UPGRADE_BUTTON: 'primary-color-upgrade-button',
	AUTOCOMPLETE_POPUP: 'autocomplete-popup',
	CUSTOM_WRITING_STYLES: 'custom-writing-styles',
	BLUR_PAST_250_WORDS: 'blur-past-250-words',
	WORD_COUNT: 'word-count-2',
	DEFAULT_WORD_COUNT: 'default-word-count',
	EMPHASIZE_WRITER_OPTION: 'emphasize-writer-option',
	REF_STYLES: 'ref-styles',
	ANNUAL_PLAN: 'annual-plan',
	REMOVE_ESSAY_TYPE: 'remove-essay-type',
	REMOVE_CHOOSE_SUBTOPICS: 'remove-choose-subtopics',
	HIDE_UPGRADE_BUTTON: 'hide-upgrade-button',
	BLUR_PANEL: 'blur-panel',
	EXA_REFERENCES: 'exa-references',
	PHILIPPINES_PRICING: 'philippines-pricing',
	STEALTH_GPT: 'stealth-gpt',
	HUMANIZER_LOCK: 'humanizer-lock',
	PROMPT_QUALITY_BAR: 'prompt-quality-bar',
}

export const usePricing = () => {
	const variant = useFeatureFlagVariantKey(FEATURE_FLAGS.ANNUAL_PLAN)
	const philippinesPricingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PHILIPPINES_PRICING)

	const variantToPrice = {
		control: {
			monthly: '9',
			yearly: null,
			yearlyTotal: null,
			discount: null,
			stripePriceId: { yearly: null, monthly: 'price_1PsqLqApflHFLqu6N1DpjqlU' },
		},
		'9': {
			monthly: '9',
			yearly: '7',
			yearlyTotal: '84',
			discount: '22',
			stripePriceId: { yearly: 'price_1PxBHgApflHFLqu6VzPI2Vby', monthly: 'price_1PsqLqApflHFLqu6N1DpjqlU' },
		},
		'12': {
			monthly: '12',
			yearly: '9',
			yearlyTotal: '108',
			discount: '25',
			stripePriceId: { yearly: 'price_1PxBG5ApflHFLqu67sFPbUgR', monthly: 'price_1PuC7JApflHFLqu67Hm1tR03' },
		},
		'14': {
			monthly: '14',
			yearly: '9',
			yearlyTotal: '108',
			discount: '35',
			stripePriceId: { yearly: 'price_1PxBG5ApflHFLqu67sFPbUgR', monthly: 'price_1PsqM8ApflHFLqu6HolXtqsE' },
		},
	}
	const philippinesPricing = {
		control: {
			monthly: {
				control: '400',
				'40': '300',
			},
			yearly: {
				control: null,
				'40': null,
			},
			yearlyTotal: {
				control: null,
				'40': null,
			},
			discount: {
				control: null,
				'40': null,
			},
			stripePriceId: {
				yearly: {
					control: null,
					'40': null,
				},
				monthly: {
					control: 'price_1Pyd2OApflHFLqu6dCeBSFOI',
					'40': 'price_1Pyd15ApflHFLqu6ekx5wHMk',
				},
			},
		},
		'9': {
			monthly: {
				control: '400',
				'40': '300',
			},
			yearly: {
				control: '292',
				'40': '233',
			},
			yearlyTotal: {
				control: '3500',
				'40': '2800',
			},
			discount: {
				control: '27',
				'40': '22',
			},
			stripePriceId: {
				yearly: {
					control: 'price_1Pyd4cApflHFLqu6LnNTg4ST',
					'40': 'price_1Pyd4FApflHFLqu6rCWfWwRx',
				},
				monthly: {
					control: 'price_1Pyd2OApflHFLqu6dCeBSFOI',
					'40': 'price_1Pyd15ApflHFLqu6ekx5wHMk',
				},
			},
		},
		'12': {
			monthly: {
				control: '500',
				'40': '400',
			},
			yearly: {
				control: '375',
				'40': '300',
			},
			yearlyTotal: {
				control: '4500',
				'40': '3600',
			},
			discount: {
				control: '30',
				'40': '25',
			},
			stripePriceId: {
				yearly: {
					control: 'price_1Pyd5QApflHFLqu6wU9pdAkl',
					'40': 'price_1Pyh7bApflHFLqu6WFnWPgEQ',
				},
				monthly: {
					control: 'price_1Pyd2rApflHFLqu63QeqJdvS',
					'40': 'price_1Pyd2OApflHFLqu6dCeBSFOI',
				},
			},
		},
		'14': {
			monthly: {
				control: '590',
				'40': '470',
			},
			yearly: {
				control: '375',
				'40': '300',
			},
			yearlyTotal: {
				control: '4500',
				'40': '3600',
			},
			discount: {
				control: '36',
				'40': '35',
			},
			stripePriceId: {
				yearly: {
					control: 'price_1Pyd5QApflHFLqu6wU9pdAkl',
					'40': 'price_1Pyh7bApflHFLqu6WFnWPgEQ',
				},
				monthly: {
					control: 'price_1Pyd3iApflHFLqu6BOCVqEMS',
					'40': 'price_1Pyd3JApflHFLqu6pjuDdoGb',
				},
			},
		},
	}

	if (['control', '40'].includes(philippinesPricingFF as string)) {
		// @ts-ignore
		const basePricing = philippinesPricing[variant ?? 'control']
		return {
			monthly: basePricing.monthly[philippinesPricingFF] as string,
			yearly: basePricing.yearly[philippinesPricingFF] as string | null,
			yearlyTotal: basePricing.yearlyTotal[philippinesPricingFF] as string | null,
			discount: basePricing.discount[philippinesPricingFF] as string | null,
			stripePriceId: {
				yearly: basePricing.stripePriceId.yearly[philippinesPricingFF] as string | null,
				monthly: basePricing.stripePriceId.monthly[philippinesPricingFF] as string,
			},
		}
	}

	// @ts-ignore
	return variantToPrice[variant ?? 'control'] as {
		monthly: string
		yearly: string | null
		yearlyTotal: string | null
		discount: string | null
		stripePriceId: { yearly: string | null; monthly: string }
	}
}

export const useFreeWordLimit = () => {
	const variant = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)

	const variantToLimit = {
		control: 250,
		'150': 150,
		'350': 350,
	}

	// @ts-ignore
	return variantToLimit[variant ?? 'control'] as 150 | 250 | 350
}
