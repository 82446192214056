import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { DocumentState } from '../../redux/types'
import { selectDocumentState, setIsOnSubtopicsStep, setUserWriterSelections } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import './_Subtopics.scss'
import Button from '../../Components/Buttons'
import { useMemo } from 'react'
import { ChevronLeft } from '@mui/icons-material'
import { useProcessedSubtopics } from './hooks'

const SubTopics = ({ generateEssay }: { generateEssay: () => void }) => {
	const dispatch = useDispatch()
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const processedSubtopics = useProcessedSubtopics()

	return (
		<div className="subtopics relative">
			<Button
				type="tertiary"
				className="absolute left-0 top-0 sm:block hidden"
				onClick={() =>
					dispatch(
						setIsOnSubtopicsStep({
							docID: docID,
							isOnSubtopicsStep: false,
						})
					)
				}
			>
				<div className="flex items-center gap-2">
					<div className="inline-block text-md w-2 relative right-2">
						<ChevronLeft />
					</div>
					<div>Back</div>
				</div>
			</Button>
			<h6 className="header-sm">Enter subtopics:</h6>
			<p>We've written these to help get you started.</p>
			{processedSubtopics.map((subtopic, index) => {
				const isIntroOrConclusion = index === 0 || index === processedSubtopics.length - 1
				return (
					<TextareaAutosize
						placeholder="Type a subtopic here..."
						className={`subtopicInput ${isIntroOrConclusion ? 'disabled' : ''}`}
						autoFocus
						value={subtopic}
						disabled={isIntroOrConclusion}
						onChange={(e) => {
							dispatch(
								setUserWriterSelections({
									docID: docID,
									userWriterSelections: {
										...documentState.userWriterSelections,
										subTopics: documentState.userWriterSelections.subTopics.map((sub, i) =>
											i === index ? e.target.value : sub
										),
									},
								})
							)
						}}
						minRows={1}
					/>
				)
			})}
			<Button type="secondary" onClick={generateEssay} className="generateEssayButton" emphasize>
				Generate Essay
			</Button>
		</div>
	)
}

export default SubTopics
