import Modal from './Modal'
import './_Thanks.scss'

function Thanks({ open, closeModal }: { open: boolean; closeModal: () => void }) {
	return (
		<Modal open={open} closeModal={closeModal} portalClassName="thanks">
			<h5 className="text-lg mb-0 z-20 mt-4">Thank you!</h5>
			<p>Your purchase was successful.</p>
			<div className="relative">
				<div className="spinner">
					<span></span>
				</div>
			</div>
		</Modal>
	)
}

export default Thanks
