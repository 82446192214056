import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Document from '../Document'
import { selectJWToken, selectUser, selectUserPagesCache, setJWToken, setUserPagesCache } from '../../redux/systemSlice'
import { selectDocumentState, setLastSavedContent, setLastSavedTitle, setSetupChanged } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import * as Sentry from '@sentry/react'
import { DocumentState } from '../../redux/types'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import { useLocalStorage } from '../../helpers/utility'
import DocumentBlur from './Blur'
import AddMore from '../AddMore/AddMore'
import References from './References'

type DocumentProps = {
	docID: string
	type: string | undefined
}

function DocumentWrapper(props: DocumentProps) {
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const userPagesCache = useSelector(selectUserPagesCache)
	const { currentUser } = useAuth() as any
	const jwtToken = useSelector(selectJWToken)
	const blurPast250Words = useFeatureFlagVariantKey(FEATURE_FLAGS.BLUR_PAST_250_WORDS)
	const freeWordLimit = useFreeWordLimit()
	const [localUserWriterSelections] = useLocalStorage(props.docID, null)

	useEffect(() => {
		const timer = setTimeout(async () => {
			if (!documentState) return
			if (
				documentState.setupChanged ||
				documentState.lastSavedContent !== (documentState.content ?? '') ||
				documentState.lastSavedTitle !== (documentState.title ?? '')
			) {
				const targetTitle = documentState.title ?? ''
				const targetContent = documentState.content ?? ''
				const setup = documentState?.setup ?? true
				dispatch(setUserPagesCache({ ...userPagesCache, dirty: true }))

				const requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
					},
					body: JSON.stringify({
						content: targetContent,
						id: props.docID,
						title: targetTitle,
						setup: setup,
					}),
				}
				fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
					.then((res) => res.json())
					.then((success: boolean) => {
						if (success) {
							dispatch(setLastSavedContent({ docID: props.docID, lastSavedContent: targetContent }))
							dispatch(setLastSavedTitle({ docID: props.docID, lastSavedTitle: targetTitle }))
							dispatch(setSetupChanged({ docID: props.docID, setupChanged: false }))
						}
					})
					.catch((err) => {
						Sentry.captureException(err)
					})
			}
		}, 500)
		return () => clearTimeout(timer)
	}, [documentState, dispatch, props.docID, currentUser, userPagesCache])

	useEffect(() => {
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/generate-token/')
			.then((res) => res.json())
			.then((response: { token: string }) => {
				if (response) {
					dispatch(setJWToken(response.token))
				}
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}, [dispatch])

	return (
		<div className="editSection mt-8">
			{documentState && documentState.setup && jwtToken && <Document docID={props.docID} />}
			{blurPast250Words &&
				documentState &&
				((documentState && documentState.userWriterSelections?.wordCount > freeWordLimit) ||
					// @ts-ignore
					(localUserWriterSelections && localUserWriterSelections?.wordCount > freeWordLimit)) &&
				!user.planId &&
				documentState.setup &&
				!documentState.isGenerating && <DocumentBlur docId={props.docID} />}
			{documentState && documentState.setup && (
				<AddMore
					type={props.type}
					docID={props.docID}
					// During content typing, toggle addMore buttons between sticky and fixed
					isFixed={false}
				/>
			)}
			<References docId={props.docID} />
		</div>
	)
}

export default DocumentWrapper
