import { REF_STYLES, ReferencesInfo as ReferenceInfo } from '../redux/types'

export function titleCase(str: string) {
	const words = str.toLowerCase().split(' ')
	for (var i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
	}
	return words.join(' ')
}

export const formatReferenceForInText = (style: string, info: ReferenceInfo) => {
	const { authors, publishedDate, page } = info

	// Convert published date to year
	const date = new Date(publishedDate || Date.now())
	const year = date.getFullYear()

	// Helper function to format author names for in-text citations
	const formatAuthorForCitation = (authors: string[]): string => {
		if (authors.length === 1) return authors[0].split(' ').pop()!
		if (authors.length === 2) return `${authors[0].split(' ').pop()} and ${authors[1].split(' ').pop()}`
		return `${authors[0].split(' ').pop()} et al.`
	}

	const formatAuthorIEEE = (authors: string[]): string => {
		return authors.map((author) => author.split(' ').pop()).join(', ')
	}

	// Format in-text citation based on the style
	let inTextCitation = ''

	switch (style) {
		case 'APA_7':
			inTextCitation = `(${formatAuthorForCitation(authors ?? [])}, ${year}${page ? `, p. ${page}` : ''})`
			break
		case 'Harvard':
			inTextCitation = `(${formatAuthorForCitation(authors ?? [])} ${year}${page ? `, p. ${page}` : ''})`
			break
		case 'MLA_9':
			inTextCitation = `(${formatAuthorForCitation(authors ?? [])}${page ? ` ${page}` : ''})`
			break
		case 'CHICAGO':
			inTextCitation = `${formatAuthorForCitation(authors ?? [])} (${year}${page ? `, ${page}` : ''})`
			break
		case 'IEEE':
			inTextCitation = `[${formatAuthorIEEE(authors ?? [])}, ${year}]`
			break
		default:
			throw new Error(`Unsupported reference style: ${style}`)
	}

	return inTextCitation
}

export const formatReference = (style: string, info: ReferenceInfo, index: number) => {
	let { url, title, authors, publishedDate } = info

	const shortenedAuthors = authors?.length && authors.length > 7 ? authors.slice(0, 7) : authors

	// Convert the published date to the desired format
	const date = publishedDate ? new Date(publishedDate) : new Date()
	const year = date.getFullYear()
	const monthName = date.toLocaleString('default', { month: 'long' })
	const monthNumeric = (date.getMonth() + 1).toString().padStart(2, '0')
	const day = date.getDate()

	// Helper functions to format the author's name
	const formatAuthorsAPA = (authors: string[]): string => {
		return authors
			.map((author, index) => {
				const names = author.trim().split(' ')
				const lastName = names.pop()
				const initials = names.map((n) => n.charAt(0).toUpperCase() + '.')

				// Handle single-name authors
				if (names.length === 0) {
					// No last name provided, use the first name as last name
					return `${lastName}`
				}

				const formattedName = `${lastName}, ${initials.join(' ')}`
				if (index === authors.length - 1 && authors.length > 1) {
					return `& ${formattedName}`
				}
				return formattedName
			})
			.join(', ')
	}
	const formatAuthorsHarvard = (authors: string[]): string => {
		return authors
			.map((author, index) => {
				const names = author.trim().split(' ')
				const lastName = names.pop()
				const firstNames = names.join(' ')

				// Handle single-name authors
				if (names.length === 0) {
					return `${lastName}`
				}

				const formattedName = `${lastName}, ${firstNames}`
				if (index === authors.length - 1 && authors.length > 1) {
					return `and ${formattedName}`
				}
				return formattedName
			})
			.join(', ')
	}
	const formatAuthorsMLA = (authors: string[]): string => {
		const formattedAuthors = authors.map((author) => {
			const names = author.trim().split(' ')
			if (names.length === 1) {
				// Single-name author
				return names[0]
			}
			const lastName = names.pop()
			const firstNames = names.join(' ')
			return `${lastName}, ${firstNames}`
		})

		if (formattedAuthors.length === 1) {
			return formattedAuthors[0]
		} else if (formattedAuthors.length === 2) {
			return `${formattedAuthors[0]} and ${formattedAuthors[1]}`
		} else {
			return `${formattedAuthors[0]}, et al.`
		}
	}

	const formatAuthorsChicago = (authors: string[]): string => {
		const formattedAuthors = authors.map((author) => {
			const names = author.trim().split(' ')
			if (names.length === 1) {
				// Single-name author
				return names[0]
			}
			const lastName = names.pop()
			const firstNames = names.join(' ')
			return `${lastName}, ${firstNames}`
		})

		if (formattedAuthors.length === 1) {
			return formattedAuthors[0]
		} else {
			return `${formattedAuthors[0]} et al.`
		}
	}

	const formatAuthorsIEEE = (authors: string[]): string => {
		return authors
			.map((author) => {
				const names = author.trim().split(' ')
				if (names.length === 1) {
					// Single-name author
					return names[0]
				}
				const lastName = names.pop()
				const initials = names.map((n) => n.charAt(0).toUpperCase() + '.')
				return `${initials.join(' ')} ${lastName}`
			})
			.join(', ')
	}

	let formattedReference = <></>

	switch (style) {
		case 'APA_7':
			formattedReference = (
				<>
					{`${formatAuthorsAPA(shortenedAuthors ?? [])} (${year}). `}
					<i>{`${title}.`}</i> Retrieved from{' '}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		case 'HARVARD':
			formattedReference = (
				<>
					{`${formatAuthorsHarvard(shortenedAuthors ?? [])} (${year}) ${title}. Available at: `}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>{' '}
					(Accessed: {day} {monthName} {year}).
				</>
			)
			break
		case 'MLA_9':
			formattedReference = (
				<>
					{`${formatAuthorsMLA(shortenedAuthors ?? [])}. "${title}." `}
					<i>{title}</i>, {day} {monthName} {year},{' '}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		case 'CHICAGO':
			formattedReference = (
				<>
					{`${formatAuthorsChicago(shortenedAuthors ?? [])}. "${title}." Last modified ${monthName} ${day}, ${year}. `}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		case 'IEEE':
			formattedReference = (
				<>
					{`${formatAuthorsIEEE(shortenedAuthors ?? [])}, "`}
					{`${title}," `}
					<i>{title}</i>, {`accessed ${monthNumeric}/${day}/${year}`}. [Online]. Available:{' '}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		default:
			throw new Error(`Unsupported reference style: ${style}`)
	}

	return (
		<div key={index} className="text-lg leading-loose primary-font">
			{formattedReference}
		</div>
	)
}
