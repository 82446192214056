import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { Tooltip } from '@mui/material'
import Button from '../Buttons'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import ReferencesDropdown from './ReferencesDropdown'

const ReferencesSelection = ({ docID }: { docID: string }) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	const refStylesFF = useFeatureFlagVariantKey(FEATURE_FLAGS.REF_STYLES)

	if (refStylesFF !== 'test') {
		return (
			<div
				className="refsContainer"
				onClick={(e) => {
					const includeRefs =
						documentState && documentState.userWriterSelections && !!documentState?.userWriterSelections?.refStyle

					dispatch(
						setUserWriterSelections({
							docID: docID,
							userWriterSelections: {
								refStyle: includeRefs ? null : 'APA_7',
							},
						})
					)
				}}
			>
				<Tooltip
					title="The AI model cannot ensure all references are accurate. Additionally, the model will not always use outside sources when constructing your essay."
					placement="top-start"
				>
					<h6 id="refsLabel" className="header-sm">
						Include References?
					</h6>
				</Tooltip>

				<Button
					className={`refsCheckbox ${documentState.userWriterSelections?.refStyle ? 'checked' : ''}`}
					type={'secondary'}
				>
					<p className="fancy disable">{documentState.userWriterSelections?.refStyle ? 'X' : ' '}</p>
				</Button>
			</div>
		)
	}

	return (
		<div
			className="flex items-center justify-between mt-10"
			onClick={(e) => {
				const includeRefs =
					documentState && documentState.userWriterSelections && !!documentState?.userWriterSelections?.refStyle

				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							includeRefs: includeRefs,
						},
					})
				)
			}}
		>
			<Tooltip
				title="The AI model cannot ensure all references are accurate. Additionally, the model will not always use outside sources when constructing your essay."
				placement="top-start"
			>
				<h6 id="refsLabel" className="header-sm">
					References Style:
				</h6>
			</Tooltip>
			<ReferencesDropdown docID={docID} />
		</div>
	)
}

export default ReferencesSelection
