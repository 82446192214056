import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Loader from '../Components/Loader'
import { useQuery } from '../helpers/queryParams'
import { titleCase } from '../helpers/strings'
import * as Sentry from '@sentry/react'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import './_Examples.scss'
import { Helmet } from 'react-helmet-async'
import Footer from '../Components/Footer'

export type Post = {
	title: string
	path: string
	image?: string
	preview?: string
}

const categories = [
	'literature',
	'technology',
	'memoir',
	'math',
	'physics',
	'philosophy',
	'engineering',
	'finance',
	'economics',
	'chemistry',
	'biology',
	'music',
	'comparative',
	'history',
	'art',
	'geography',
	'religion',
]

function Examples() {
	const pageName = 'examples'
	const [posts, setPosts] = useState<Post[]>([])
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()

	// Get the current category from the query params, if none is provided, show all categories
	const query = useQuery()
	const category = query.get('category')

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [query])

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/examples' }))
		setPosts([])

		// If no category is provided, don't fetch any posts
		if (!category) {
			setLoading(false)
			return
		}

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ category }),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/cms/category-list/', requestOptions)
			.then((res) => res.json())
			.then((data: Post[]) => {
				setPosts(data)
				setLoading(false)
			})
			.catch((err) => Sentry.captureException(err))
	}, [dispatch, category])

	const renderPosts = useMemo(() => {
		if (loading) return <Loader />

		if (category) {
			return posts.map((post, index) => (
				<Link to={'/post/' + post.path} key={'post' + index}>
					<h5 className="item md:text-3xl text-2xl md:my-8 my-6">
						{post.title}
						<span className="arrow text-5xl">⤑</span>
					</h5>
				</Link>
			))
		}
		return categories.map((category, index) => (
			<Link to={'/examples?category=' + category} key={'category' + index}>
				<h4 className="item text-3xl md:my-8 my-6">
					{titleCase(category)}
					<span className="arrow text-5xl">⤑</span>
				</h4>
			</Link>
		))
	}, [loading, posts, category])

	return (
		<>
			<Helmet>
				<title>
					The Good AI | Examples of AI Written Essays | {category ? `— ${titleCase(category)}` : 'Categories'}
				</title>
				<meta name="description" content="Examples of AI written essays." />
				<meta name="keywords" content="ai, artificial intelligence, essay, examples, sample, ai essay" />
			</Helmet>
			<main id={pageName}>
				<div className="px-4 lg:px-56 pt-24 max-w-[1800px] mx-auto">
					<h4 className="md:text-5xl text-4xl py-2">
						<span className="highlighted">
							AI-Generated Essays{' '}
							<span className="fancy">{category ? `— ${titleCase(category)}` : '— Categories'}</span>
						</span>
					</h4>
					<hr className="border-t-0 border border-primary border-dotted" />
					{renderPosts}
				</div>
			</main>
			<Footer />
		</>
	)
}

export default withRouter(Examples)
