import { useAtom } from 'jotai'
import { accountModalAtom } from '../../Pages/Create/Create'
import { CONSTANTS } from '../../constants'
import { useProcessedSubtopics } from '../../Pages/Create/hooks'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import { selectDocumentState } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { useSelector } from 'react-redux'
import Button from '../Buttons'
import { useFeatureFlagVariantKey } from 'posthog-js/react'

const DocumentBlur = ({ docId }: { docId: string }) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const [, setAccountModalState] = useAtom(accountModalAtom)
	const freeWordLimit = useFreeWordLimit()
	const processedSubtopics = useProcessedSubtopics()
	const blurPanel = useFeatureFlagVariantKey(FEATURE_FLAGS.BLUR_PANEL)

	if (blurPanel !== 'test') {
		return (
			<div className="flex flex-col gap-2 mt-4">
				<div className="text-xl font-semibold">
					Unlock your full essay and go AI-undetectable.{' '}
					<span
						className="text-link underline cursor-pointer"
						onClick={() => {
							setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: true }))
						}}
					>
						Go Premium
					</span>
				</div>
				<div
					className="blur-sm h-96 px-1 overflow-hidden"
					onClick={() => {
						setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: true }))
					}}
				>
					{documentState.userWriterSelections?.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT && (
						<div className="text-3xl leading-loose">
							{freeWordLimit > 250 ? processedSubtopics[2] : processedSubtopics[1]}
						</div>
					)}
					<div className="text-xl leading-loose mt-4">
						The concept of "test" encompasses a wide array of meanings across various disciplines, from education to
						psychology and even medicine. In the educational context, tests are often utilized as tools for assessment,
						serving to measure a student's understanding and mastery of specific content areas. The significance of
						tests in education cannot be overstated; they provide both educators and learners with critical feedback
						regarding academic progress and comprehension. However, the implications of testing extend beyond mere
						evaluation; they also influence teaching methodologies, curriculum development, and student motivation. In
						educational settings, tests can take various forms including formative assessments such as quizzes or
						midterms, as well as summative assessments like final exams or standardized tests. Each type serves distinct
						purposes: formative assessments are designed to monitor student learning and provide ongoing feedback that
						can be used by instructors to improve their teaching strategies. Conversely, summative assessments evaluate
						cumulative knowledge at the end of an instructional unit. This distinction is essential because it
						highlights how testing can serve dual roles in both guiding instruction and measuring outcomes.
					</div>
				</div>
			</div>
		)
	}

	return (
		<div
			className="overflow-hidden relative md:h-96 h-[500px]"
			onClick={() => {
				setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: true }))
			}}
		>
			<div className="blur-sm pl-1">
				{documentState.userWriterSelections?.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT && (
					<div className="text-3xl leading-loose">
						{freeWordLimit > 250 ? processedSubtopics[2] : processedSubtopics[1]}
					</div>
				)}
				<div className="text-xl leading-loose mt-2 h-48">
					Lorem ipsum odor amet, consectetuer adipiscing elit. Facilisis semper velit curabitur dignissim augue quisque
					suspendisse aenean. Ipsum eu proin commodo a dolor ad maecenas et. Sed aptent purus platea leo est conubia
					dictum nascetur? Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce. Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce. Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce.
				</div>
			</div>
			<div className="bg-secondary w-full absolute bottom-0 flex items-center justify-center flex-col gap-3 py-9 px-3">
				<div className="text-2xl font-semibold text-center">
					Unlock your full essay and go <span className="fancy font-normal">AI-undetectable</span>
				</div>
				<div className="text-lg text-gray-700 max-w-[650px] text-center">
					Get access to your entire essay, unlimited humanizing, unlimited AI generations, and autocomplete.
				</div>
				<Button
					type="primary"
					className="border border-primary px-9 py-2 bg-white hover:bg-gray-100 font-semibold border-dotted"
				>
					Upgrade Now
				</Button>
			</div>
		</div>
	)
}

export default DocumentBlur
