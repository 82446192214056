import { useSelector } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { selectUser } from '../../redux/systemSlice'
import Button from '../Buttons'
import { CONSTANTS } from '../../constants'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit, usePricing } from '../../hooks/featureFlags'
import useCheckout from '../../hooks/checkout'
import { FaRegCircleCheck } from 'react-icons/fa6'
import UsersImg from '../../assets/images/users.png'

function Premium({
	closeParentModal,
	setLoginModal,
	annualPlan,
	setAnnualPlan,
	shorten,
}: {
	closeParentModal: () => void
	setLoginModal: (open: boolean) => void
	annualPlan: boolean
	setAnnualPlan: (annual: boolean) => void
	shorten?: boolean
}) {
	const philippinesPricingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PHILIPPINES_PRICING)
	const posthog = usePostHog()
	const { checkout } = useCheckout()
	const pricing = usePricing()
	const user = useSelector(selectUser)
	const { currentUser } = useAuth() as any
	const freeWordLimit = useFreeWordLimit()
	function openNextStep() {
		posthog.capture('upgrade-now-clicked')
		if (!currentUser) {
			setLoginModal(true)
			closeParentModal()
		} else {
			checkout(annualPlan)
		}
	}

	return (
		<div className={`flex flex-col ${shorten ? 'gap-3' : !!pricing.yearly ? 'gap-4' : 'gap-6'}`}>
			<h6 className="text-2xl text-left">Upgrade to Premium</h6>
			<p className="text-[18px] text-gray-700 mb-0 text-left">
				<span className="bg-secondary fancy text-primary">Supercharge your essay writing</span> with a premium plan.
			</p>
			{!!pricing.yearly && (
				<div className="flex flex-col items-center">
					<div className="rounded-full p-1 flex gap-2 border border-gray-400">
						<div
							className={`cursor-pointer rounded-full py-1 px-3 text-sm font-semibold ${
								!annualPlan ? 'bg-gray-300 text-primary' : 'text-gray-700'
							}`}
							onClick={() => {
								setAnnualPlan(false)
							}}
						>
							Monthly
						</div>
						<div
							className={`cursor-pointer rounded-full py-1 px-3 text-sm font-semibold ${
								annualPlan ? 'bg-gray-300 text-primary' : 'text-gray-700'
							}`}
							onClick={() => {
								setAnnualPlan(true)
							}}
						>
							Annual
						</div>
					</div>
				</div>
			)}
			<div className="flex justify-between flex-wrap gap-4">
				{!shorten && (
					<div className="flex flex-col gap-2">
						<h6 className="text-2xl text-left font-bold mt-1">FREE</h6>
						<div className="flex flex-col gap-2">
							{[`${freeWordLimit} Word Essays`, 'Unlimited Essays', 'Basic Essay Options'].map((item, index) => {
								if (!item) return null
								return (
									<div key={index} className="flex gap-2 items-center">
										<FaRegCircleCheck className="text-success-default text-xl" />
										<div>{item}</div>
									</div>
								)
							})}
						</div>
					</div>
				)}
				<div className="flex flex-col gap-2">
					<div className="flex flex-col gap-2">
						<div className="flex gap-1 items-baseline">
							<div className="text-3xl font-bold leading-0 m-0">
								{['control', '40'].includes(philippinesPricingFF as string) ? '₱' : '$'}
								{annualPlan ? pricing.yearly : pricing.monthly}
							</div>
							<div className="text-sm relative bottom-0.5">/month</div>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						{[
							`${CONSTANTS.WORD_COUNT_MAX} Word Essays`,
							'Unlimited Essays',
							'Advanced Essay Options',
							'AI Humanizer & Detector',
						].map((item, index) => {
							if (!item) return null
							return (
								<div key={index} className="flex gap-2 items-center">
									<FaRegCircleCheck className="text-success-default text-xl" />
									<div>{item}</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			{/* {pricing.yearly && (
				<div className="flex flex-col gap-3">
					<div
						className={`flex justify-between border px-3 py-2 cursor-pointer ${
							annualPlan ? 'border-[#20718B] border-2 bg-gray-100' : 'border-gray-400'
						}`}
						onClick={() => {
							setAnnualPlan(true)
							setAnnualPlan(true)
						}}
					>
						<div className="flex justify-between gap-2 items-center">
							<div className="font-semibold text-sm">Annual</div>
							<div className="border border-success-default bg-success-light rounded-lg text-xs text-success-dark px-1">
								Save {pricing.discount}%
							</div>
						</div>
						<div className="text-sm font-semibold">${pricing.yearly}.00/mo</div>
					</div>
					<div
						className={`flex justify-between border px-3 py-2 cursor-pointer ${
							!annualPlan ? 'border-[#20718B] border-2 bg-gray-100' : 'border-gray-400'
						}`}
						onClick={() => {
							setAnnualPlan(false)
							setAnnualPlan(false)
						}}
					>
						<div className="flex justify-between gap-2 items-center">
							<div className="font-semibold text-sm">Monthly</div>
						</div>
						<div className="text-sm font-semibold">${pricing.monthly}.00/mo</div>
					</div>
				</div>
			)} */}
			<Button
				type="primary"
				htmlType="submit"
				onClick={openNextStep}
				emphasize
				className={`${shorten ? 'py-1' : 'py-2'} border border-primary font-semibold text-xl`}
			>
				<span>Get started</span>
			</Button>
			<div className="flex gap-2 items-center">
				<img src={UsersImg} alt="Users" className="h-12" />
				<div className="flex flex-col gap-0 items-start">
					<p className="m-0 text-sm">Join over 3 million students</p>
					<p className="m-0 text-sm">writing with The Good AI</p>
				</div>
			</div>
		</div>
	)
}

export default Premium
