import { useDispatch, useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { TbSparkles } from 'react-icons/tb'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { FaPlus } from 'react-icons/fa6'
import { useState } from 'react'
import Modal from '../Modals/Modal'
import { EditorContent, useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import Button from '../Buttons'
import { accountModalAtom } from '../../Pages/Create/Create'
import { useAtom } from 'jotai'
import { selectUser } from '../../redux/systemSlice'

const CustomWritingStyleModal = ({
	docID,
	modalOpen,
	setModalOpen,
}: {
	docID: string
	modalOpen: boolean
	setModalOpen: (open: boolean) => void
}) => {
	const [loading, setLoading] = useState(false)
	const editor = useEditor({
		extensions: [
			Document,
			Text,
			Paragraph,
			Placeholder.configure({
				placeholder: 'Paste your sample text here...',
			}),
		],
		content: '',
	})
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()

	const generateCustomWritingStyle = () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ referenceText: editor?.getText() }),
		}
		setLoading(true)

		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/generate-writing-style/', requestOptions)
			.then((res) => res.json())
			.then((response: { shortDescription: string; longDescription: string }) => {
				setLoading(false)
				setModalOpen(false)
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							...documentState.userWriterSelections,
							writingStyle: {
								referenceText: editor?.getText(),
								description: response.shortDescription,
								longDescription: response.longDescription,
								generalSelected: false,
							},
						},
					})
				)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return (
		<Modal open={modalOpen} closeModal={() => setModalOpen(false)} portalClassName="customWritingStyle">
			<div className="flex flex-col gap-4 mt-5">
				<div className="flex flex-col gap-1">
					<div className="text-xl font-semibold text-center">Add a custom writing style</div>
					<div className="text-sm text-gray-800 text-center">
						Add a custom writing style for your essay. Paste a sample text below to generate a writing style.
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<EditorContent
						editor={editor}
						id="custom-style-editor"
						className="border border-gray-500 rounded max-h-96 overflow-auto"
						placeholder="Paste your sample text here..."
					/>
					<Button
						type="secondary"
						emphasize
						className="border border-gray-600"
						disabled={(editor?.getText().length ?? 0) < 10}
						onClick={() => {
							generateCustomWritingStyle()
						}}
					>
						{loading ? (
							'Thinking...'
						) : documentState.userWriterSelections.writingStyle.description ? (
							<span>Save changes</span>
						) : (
							<span>Generate Style</span>
						)}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

const WritingStyleSelection = ({ docID }: { docID: string }) => {
	const user = useSelector(selectUser)
	const [modalOpen, setModalOpen] = useState(false)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const [, setAccountModalState] = useAtom(accountModalAtom)
	const dispatch = useDispatch()
	if (!documentState.userWriterSelections.writingStyle) {
		return null
	}
	const generalSelected = !!documentState.userWriterSelections.writingStyle?.generalSelected
	const customWritingStyleGenerated = !!documentState.userWriterSelections.writingStyle.referenceText

	return (
		<>
			<CustomWritingStyleModal docID={docID} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<div className="flex flex-col gap-4 mt-10">
				<div className="text-xl font-semibold text-center">Select a writing style</div>
				<div className="grid grid-cols-[1fr,1fr] md:grid-cols-[1fr,80px,1fr] gap-2 justify-center flex-wrap">
					<div
						className={` cursor-pointer border px-6 py-4 rounded border-gray-600 flex flex-col hover:shadow-md  ${
							generalSelected ? 'bg-secondary' : ''
						}`}
						onClick={() => {
							dispatch(
								setUserWriterSelections({
									docID: docID,
									userWriterSelections: {
										...documentState.userWriterSelections,
										writingStyle: {
											...documentState.userWriterSelections.writingStyle,
											generalSelected: true,
										},
									},
								})
							)
						}}
					>
						<div className="text-lg font-semibold">Basic</div>
						<div className="text-sm text-gray-800">College reading level with an academic tone.</div>
					</div>
					<div className="text-gray-800 mt-8 md:block hidden text-center">Or</div>
					<div
						className={`cursor-pointer border px-6 py-4 rounded border-gray-600 flex flex-col hover:shadow-md ${
							!generalSelected ? 'bg-secondary' : ''
						}`}
						onClick={() => {
							setModalOpen(true)
						}}
					>
						<div className="flex gap-1 items-center">
							{customWritingStyleGenerated ? <TbSparkles /> : <FaPlus />}
							<div className="text-lg font-semibold">{customWritingStyleGenerated ? 'Your Style' : 'Add Custom'}</div>
						</div>
						<div className="text-sm text-gray-800">
							{customWritingStyleGenerated
								? documentState.userWriterSelections.writingStyle?.description
								: 'Make your essay sound like you.'}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default WritingStyleSelection
