import React, { RefObject, forwardRef } from 'react'
import './_index.scss'

type buttonProps = {
	children: React.ReactNode
	type: 'primary' | 'secondary' | 'tertiary'
	emphasize?: boolean
	onClick?: (e?: any) => void
	onMouseDown?: (e?: any) => void
	disabled?: boolean
	className?: string
	htmlType?: 'button' | 'submit' | 'reset' | undefined
	style?: React.CSSProperties
	btnRef?: RefObject<HTMLButtonElement>
}

const Button = (props: buttonProps) => {
	const disabledClassName = props.disabled ? ' disabled ' : ''
	const button = (
		<button
			ref={props.btnRef}
			onClick={() => {
				if (props.onClick && !props.disabled) {
					props.onClick()
				}
			}}
			onMouseDown={() => {
				if (props.onMouseDown && !props.disabled) {
					props.onMouseDown()
				}
			}}
			className={
				props.type +
				'Button ' +
				disabledClassName +
				(props.className ? props.className : '') +
				(props.emphasize ? ' emphasize' : '')
			}
			type={props.htmlType ?? 'button'}
			style={props.style}
		>
			{props.children}
		</button>
	)
	return button
}

export default Button
