import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import store from './redux/store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import { CONSTANTS } from './constants'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider } from './contexts/Auth'
import PrivateRoute from './Pages/PrivateRoute'
import Create from './Pages/Create/Create'
import Examples from './Pages/Examples'
import Post from './Pages/Post'
import Gallery from './Pages/Choose'
import Blog from './Pages/Blog'
import Unknown from './Pages/404'
import New from './Pages/New'
import { HelmetProvider } from 'react-helmet-async'
import Pricing from './Pages/Pricing'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import { selectUser } from './redux/systemSlice'
import { useCookieUser } from './hooks/cookieUser'
import { usePricing } from './hooks/featureFlags'
import Thanks from './Components/Modals/Thanks'
import { logPurchase } from './helpers/analytics'
import useUniqueId from './hooks/uniqueId'

export function getCookie(cname: string) {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export const contactFormLink = 'https://the-good-ai.helpscoutdocs.com'
const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

function App() {
	return (
		<React.StrictMode>
			<PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
				<Provider store={store}>
					<AuthProvider>
						<CookiesProvider>
							<HelmetProvider>
								<Routes />
							</HelmetProvider>
						</CookiesProvider>
					</AuthProvider>
				</Provider>
			</PostHogProvider>
		</React.StrictMode>
	)
}

const Home = lazy(() => import('./Pages/Home/Home'))

const Routes = () => {
	const [thanksModalOpen, setThanksModalOpen] = useState(false)
	const price = usePricing()
	const user = useSelector(selectUser)
	const { userIDCookie } = useCookieUser()
	const posthog = usePostHog()
	const searchParamsString = document.location.search
	const dispatch = useDispatch()
	const uniqueId = useUniqueId()

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			showModal: params.getAll('showModal')[0],
			annual: params.getAll('annual')[0],
		}
	}, [searchParamsString])

	useEffect(() => {
		if (!uniqueId) return
		posthog?.identify(uniqueId, {
			email: user?.email,
			id: user?.id,
			premium: user?.planId ? 'true' : 'false',
			cookie_id: userIDCookie,
		})
	}, [posthog, user.id, user.email, userIDCookie, uniqueId])

	useEffect(() => {
		if (searchParams.showModal) {
			setThanksModalOpen(true)
			const isAnnual = searchParams.annual === 'true'
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('showModal')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
			posthog?.capture('purchase', {
				price: isAnnual ? price.yearlyTotal : price.monthly,
				plan: isAnnual ? 'annual' : 'monthly',
			})
		}
	}, [searchParams, dispatch, price])

	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<Thanks
					open={thanksModalOpen}
					closeModal={() => {
						setThanksModalOpen(false)
						logPurchase()
					}}
				/>
				<Navbar />
				<Switch>
					<PrivateRoute
						loginRequired={false}
						component={(props: any) => (
							<Create type={props.match.params.type} docID={props.match.params.id ? atob(props.match.params.id) : ''} />
						)}
						path={CONSTANTS.CREATE_PATH + '/:type/:id'}
					/>
					<PrivateRoute loginRequired={false} path={CONSTANTS.DOC_PATH} component={() => <Gallery />} />
					<Route path="/post/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route path="/new/:template" render={(props) => <New template={props.match.params.template} />} />
					<Route path="/examples" render={() => <Examples />} />
					<Route path="/blog/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route path="/blog" render={() => <Blog />} />
					<Route path="/pricing" render={() => <Pricing />} />
					<Route exact path="/" render={() => <Home />} />
					<Route path="/404" component={Unknown} />
					<Redirect to="/404" />
				</Switch>
			</Suspense>
		</BrowserRouter>
	)
}

export default App
